import http from "@/utils/http";
export async function get_subscription_plans() {
    const req = (await http.get(`/subscription`));
    return req.data;   
}
export async function create_subscription(data){
    const req = (await http.post(`/subscription/create`,data));
    return req.data;      
}

export async function subscription_status(){
    const req = (await http.get(`/subscription/status`));
    return req.data;
}

export async function cancel_subscription(){
    const req = (await http.post(`/subscription/cancel`));
    return req.data;
}
 export async function attach_payment_method(data){
    const req = (await http.post(`/subscription/attach-payment-method`,data));
    return req.data;      
}

export async function get_payment_method(){
    const req = (await http.get(`/subscription/payment-method`));
    return req.data;
}

export async function delete_payment_method(data){
    const req = (await http.delete(`/subscription/payment-method`,{data}));
    return req.data;
}